<!-- ########################################################### Situation Spielrunde (info)#########################################################################################################################################################################################################   -->

<div *ngIf="step == 7" class="ngIfPlace">
  <div style="width: 100%; height: 100% ; display: flex ;justify-content: center; align-items: flex-start; position: relative;" >
      <div class="situationBox " style="width: 65%;">
        <h1>Situation Spielrunde {{spielrundeCurrent}}</h1>


      <div class="white-box" style="margin-right: 20px;" >
        <div class="situationtext" *ngIf="spielrundeCurrent==1">
          Dem Fond werden für die erste Periode {{EKZufluss| number : '1.2-2'}} € Startkapital zur Verfügung gestellt. Eine Splittung des Kapitals in unterschiedliche Anlageklassen ist möglich.
        </div>

        <div class="situationtext" *ngIf="spielrundeCurrent>1">
          Dem Fond werden diese Periode {{EKZufluss| number : '1.2-2'}} € neues Kapital zur Verfügung gestellt. Zusätzlichen stehen aus vorheriger Periode Einnahmen abzüglich Kosten zur Reinvestition bereit. Eine Splittung des Kapitals in unterschiedliche Anlageklassen ist möglich.
        </div>

      </div>
      </div>
      <img
      id="bildingImg"
      src="../../assets/img/image5.png"
      style="object-fit: cover;height:90%;border-radius: 15px;"
    />
 
  </div>

  <div class="timeIsUp" style="display: none">
    <button class="btn btn-danger">die Zeit ist abgelaufen. dein Budget wird automatisch in dein Tagesgelkonto investiert</button>
  </div>

  <div class="footerTimer">
  <!-- <button class="btn btn-primary" (click)="step = 6">Zurück</button> -->
  <div class="designTimer"> <i class="bi bi-hourglass-split"></i>
    {{ timer }}
  </div>
  <button class="btn btn-primary" (click)="gotoStep(8)">Weiter</button>
</div>
</div>


<!-- ########################################################### case(info) #########################################################################################################################################################################################################   -->


<div *ngIf="step == 8" class="ngIfPlace">
  <h1>Case</h1>

  <div class="white-box">
    Sie haben in diesem Jahr von den Anleger*innen {{budget| number : '1.2-2'}} € als Kapital für
    Ihre Investition zur Verfügung gestellt bekommen. Das gesamte Vermögen muss
    investiert werden. Dazu haben Sie folgende Auswahlmöglichkeiten:
  </div>

  
  <div  class="customDes" style="overflow-y: scroll;height: 75%;">
    <div class="row mb-5 cutomRow" style="margin-top: 50px">
      <h3 class="col cutomCol">Immobilie 1</h3>
  
      <div class="col cutomCol">
        <img src="../../assets/img/Image6.jpg" class="smallimg" />
      </div>
  
      <div class="col cutomCol">
        <div>Immobilienkaufpreis</div>
        <div>Fremdkapitalzinsen</div>
      </div>
  
      <div class="col cutomCol">
        <div>{{Hauspreis1Text| number : '1.2-2'}}€</div>
  
        <div>{{FK_Zins | number : '1.2-2'}}%</div>
      </div>
    </div>
  
    <div class="row mb-5 cutomRow" style="margin-top: 50px">
      <h3 class="col cutomCol">Immobilie 2</h3>
  
      <div class="col cutomCol">
        <img src="../../assets/img/house-1477041_640.jpg" class="smallimg"/>
      </div>
  
      <div class="col cutomCol">
        <div>Immobilienkaufpreis</div>
        <div>Fremdkapitalzinsen</div>
      </div>
  
      <div class="col cutomCol">
        <div>{{Hauspreis2Text| number : '1.2-2'}}€</div>
  
        <div>{{FK_Zins | number : '1.2-2'}}%</div>
      </div>
    </div>
  
    <div class="row mb-5 cutomRow">
      <h3 class="col cutomCol">Staatsanleihe ({{numberOfRounds-spielrundeCurrent}} Jahre)</h3>
      <div class="col cutomCol">
        <img src="../../assets/img/marketplace-7923869_640.webp" class="smallimg" />
      </div>
      <div class="col cutomCol">Rendite p.a.</div>
      <div class="col cutomCol">{{zinsST | number : '1.2-2'}}%</div>
    </div>
  
    <div class="row mb-5 cutomRow">
      <h3 class="col cutomCol">Tagesgeld (1 Jahr)</h3>
      <div class="col cutomCol">
        <img src="../../assets/img/Image8.jpg" class="smallimg" />
      </div>
      <div class="col cutomCol">Rendite p.a.</div>
      <div class="col cutomCol">{{zinsTG | number : '1.2-2'}}%</div>
    </div>
  
  </div>

  <div class="timeIsUp" style="display: none">
    <button class="btn btn-danger">die Zeit ist abgelaufen. dein Budget wird automatisch in dein Tagesgelkonto investiert</button>
  </div>
  <div class="regLog">
    <button class="btn btn-primary" (click)="gotoStep(7)">Zurück</button>
    <div class="designTimer"><i class="bi bi-hourglass-split"></i>
      {{ timer }}
    </div>
    <button class="btn btn-primary" (click)="gotoStep(9)">Weiter</button>
  </div>
</div>



<!-- ########################################################### case : gibt die whal um das Excel tool zu verwenden  #########################################################################################################################################################################################################   -->

<div *ngIf="step == 9" class="ngIfPlace">
  <h1>Case</h1>

 <div class="forResp">
  <h3>Immobilie:</h3>

  <div class="white-box">
   <div>
    Ihnen wird erstens ein Mehrfamilienhaus zu einem Preis von {{Hauspreis1Text| number : '1.2-2'}} € angeboten und zweitens ein Mehrfamilienhaus zu einem Preis von {{Hauspreis2Text| number : '1.2-2'}} €. Die zu erwartenden Mieteinnahmen des ersten Objektes belaufen sich auf {{jahresrohertrag1Text | number : '1.2-2'}} € p.a., die des zweiten auf {{jahresrohertrag2Text | number : '1.2-2'}} €. Beide Objekte sind voll vermietet, und die Nachfragesituation wird als dauerhaft günstig eingeschätzt. Die Bank bietet Ihnen hierzu eine endfällige Finanzierung über {{numberOfRounds}} Jahre zu {{FK_Zins | number : '1.2-2'}} % Zinsen bei einem LTV von maximal 50.00 % an. Ihre EK-Kosten als Fonds belaufen sich auf 2.90%. Am Ende dieses Absatzes finden Sie ein bereitgestelltes Berechnungtool, das erlaubt, den IRR und den Kapitalwert einer Investition in eines der beiden angebotenen Immobilienobjekte zu berechnen. Dabei können einfach die angezeigten, aktuellen tatsächlich gegebenen Werte für die Berechnung übernommen werden, oder diese alternativ auch gegen rein hypothetische Zahlen ausgetauscht werden, um ein Gefühl für das aktuelle Szenario gegenüber potentiellen zukünftigen Szenarien zu gewinnen. Der für Kapitalwertrechnung relevante Kalkulationszins wird, ist ohne Default-Wert, da der korrekte Wert vom sonstigen Investorenverhalten abhängt. Er sollte irgendwo zwischen dem Minimum und dem Maximum aller relevanten Verzinsungen liegen (Zu den relevanten Verzinsungen gehören sowohl die zu erwartenden jährlichen prozentualen Renditen aus den Anlageoptionen, als auch die zu zahlenden (prozentualen) Kapitalkostensätze).
   </div>
    <button class="btn btn-primary" (click)="gotoExcel()" style="margin-bottom: 20px; margin-top: 10px;">zum Berechnungstool</button>
  </div>
  

  <h3 style="margin-top:20px">Staatsanleihe:</h3>

  <div>
    Auch kann eine Anlage in deutsche Staatsanleihen zu {{zinsST | number : '1.2-2'}} % Zinsen erfolgen. Das Kapital, welches angelegt wird, kann im gesamten Spielverlauf nicht mehr neu eingesetzt werden. Die Abrechnung erfolgt stichtagsgenau im Jahr {{numberOfRounds}}.

  </div>

  <h3>Tagesgeld-Angebot:</h3>

  <div>
    Sichern Sie sich {{zinsTG | number : '1.2-2'}} % Zinsen p.a. für ein Jahr auf Ihre Kapitaleinlage. Sie
    können das eingesetzte Kapital dann in der nächsten Woche zusätzlich
    investieren.
  </div>

 </div>

  <div class="timeIsUp" style="display: none">
    <button class="btn btn-danger">die Zeit ist abgelaufen. dein Budget wird automatisch in dein Tagesgelkonto investiert</button>
  </div>

  <div class="regLog caseFooter">
    <button class="btn btn-primary" (click)="gotoStep(8)">Zurück</button>
    <div class="designTimer"><i class="bi bi-hourglass-split"></i>
      {{ timer }}
    </div>
    <button class="btn btn-primary" (click)="resizeWord();gotoStep(11)">Weiter</button>
  </div>
</div>

<!-- ########################################################### Handlungsoptionen #########################################################################################################################################################################################################   -->

<div *ngIf="step == 11" class="ngIfPlace respAnsicht" >
  <h1>Handlungsoptionen</h1>


  <div class="textChose">
    Bitte treffen Sie Ihre Entscheidung auf Basis der vorgenannten Informationen
    und Ihren Berechnungen.
  </div>

  <div class="textChose">
    Hinweis: Sie müssen als Fond das Ihnen zur Verfügung gestellte Budget
    komplett anlegen. Es besteht die Möglichkeit das Budget auf unterschiedliche
    Anlageklassen zu verteilen. Bitte tragen Sie die Werte (€) in die hellgrauen
    Felder ein.
  </div>

  <div  class="textChose">
    Mit einem Klick auf das 'Euro' Zeichen investieren Sie das gesamte Geld oder den Rest des Geldes, das noch für Investitionen zur Verfügung steht.
  </div>

  <div class="budgetCalc">
    <div>Budget(EK) {{budget| number : '1.2-2'}}€</div>
    
    <div>noch verfügbarer Betrag <span  id="rest">{{rest| number : '1.2-2'}}€</span></div>
  </div>

  <div class="contentChoose" style="overflow-y: scroll;height: 75%;">
    <div class="row mb-5 cutomRow2" style="margin-top: 50px">
      <h4 class="col cutomCol2">Buy: Immobilie 1</h4>
  
      <div class="col cutomCol2">
        <img src="../../assets/img/Image6.jpg" class="smallimg" />
      </div>
  
      <div class="col cutomCol2">
        <div>Anlagebetrag/Kaufsumme (bitte eintragen)</div>
        <div>Fremdkapitalzinsen: <span> {{FK_Zins | number : '1.2-2'}}%</span></div>
        <div>Hauspreis: {{ Hauspreis1Text | number : '1.2-2'}}€</div>
      </div>
  
      <div class="col cutomCol2">
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
          "
        >
          <div style="display: flex;flex-direction: column; width: 100%;">
            <div style="display: flex;justify-content:center; align-items:center">
          <input style="border-top-right-radius: 0;border-bottom-right-radius: 0;" min="0" id="InputImmo" type="number" class="form-control" name="InputImmo" [(ngModel)]="inputImmo" #InputImmo="ngModel" pattern="[0-9]+(\.[0-9]+)?" required (keyup)="istGesamtSummeGroesserAlsBudget()" (change)="checkRest();isRightRange()"/>
          <button  (click)="putAllMoney(1)"  class=" btn btn-primary" style="border-top-left-radius: 0;border-bottom-left-radius: 0;"><i class="bi bi-currency-euro"></i></button>
        </div>
          <div  *ngIf="!isRightRange() || !istGesamtSummeGroesserAlsBudget()||(!InputImmo.valid && (InputImmo.touched || InputImmo.dirty))" class="alert alert-danger">
            <div *ngIf="InputImmo?.errors?.['required']">muss ausgefüllt werden.</div>
            <div *ngIf="InputImmo?.errors?.['pattern']">nur positive Zahlen sind zulässig.</div>
            <div *ngIf="!isRightRange()">Der Betrag darf weder den Kaufpreis übersteigen, noch unter dem halben Kaufpreis liegen.</div>
            <div *ngIf="!istGesamtSummeGroesserAlsBudget()">Budget überschritten oder nicht vollständig investiert.</div>
          </div>
        </div>
        </div>
  
       
      </div>
    </div>
  
    <div class="row mb-5 cutomRow2" style="margin-top: 50px">
      <h4 class="col cutomCol2">Buy: Immobilie 2</h4>
  
      <div class="col cutomCol2">
        <img src="../../assets/img/house-1477041_640.jpg" class="smallimg" />
      </div>
  
      <div class="col cutomCol2">
        <div>Anlagebetrag/Kaufsumme (bitte eintragen)</div>
        <div>Fremdkapitalzinsen: <span> {{FK_Zins | number : '1.2-2'}}%</span></div>
        <div>Hauspreis: {{ Hauspreis2Text | number : '1.2-2'}}€</div>
      </div>
  
      <div class="col cutomCol2">
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
          "
        >

          <div style="display: flex;flex-direction: column; width: 100%;">
            <div style="display: flex;justify-content:center; align-items:center">
          <input  style="border-top-right-radius: 0;border-bottom-right-radius: 0;" min="0" id="InputImmo2" type="number" class="form-control" name="InputImmo2" [(ngModel)]="inputImmo2" #InputImmo2="ngModel" pattern="[0-9]+(\.[0-9]+)?" required (keyup)="istGesamtSummeGroesserAlsBudget()" (change)="checkRest();isRightRange2()"/>
          <button  (click)="putAllMoney(2)"  class=" btn btn-primary" style="border-top-left-radius: 0;border-bottom-left-radius: 0;"><i class="bi bi-currency-euro"></i></button>
        </div>
          <div  *ngIf="!isRightRange2()|| !istGesamtSummeGroesserAlsBudget()||(!InputImmo2.valid && (InputImmo2.touched || InputImmo2.dirty))" class="alert alert-danger">
            <div *ngIf="InputImmo2?.errors?.['required']">muss ausgefüllt werden.</div>
            <div *ngIf="InputImmo?.errors?.['pattern']">nur positive Zahlen sind zulässig.</div>
            <div *ngIf="!isRightRange2()">Der Betrag darf weder den Kaufpreis übersteigen, noch unter dem halben Kaufpreis liegen.</div>
            <div *ngIf="!istGesamtSummeGroesserAlsBudget()">Budget überschritten oder nicht vollständig investiert.</div>
          </div>
        </div>
        </div>
  
       
      </div>
    </div>
  
    <div class="row mb-5 cutomRow2" style="margin-top: 50px">
      <h4 class="col col cutomCol2">Buy: Staatsanleihe ({{numberOfRounds-spielrundeCurrent}} Jahre)</h4>
  
      <div class="col col cutomCol2">
        <img src="../../assets/img/marketplace-7923869_640.webp" class="smallimg" />
      </div>
  
      <div class="col col cutomCol2">
        <div>Anlagebetrag/Kaufsumme (bitte eintragen)</div>
        <div>Rendite: <span> {{zinsST | number : '1.2-2'}}%</span></div>
       
      </div>
  
      <div class="col col cutomCol2">
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
          "
        >
          <div style="display: flex;flex-direction: column; width: 100%;">
            <div style="display: flex;justify-content:center; align-items:center">
              <input style="border-top-right-radius: 0;border-bottom-right-radius: 0;" min="0" id="InputStaat" type="number" class="form-control" name="InputStaat" [(ngModel)]="inputStaat" #InputStaat="ngModel" pattern="[0-9]+(\.[0-9]+)?" required (keyup)="istGesamtSummeGroesserAlsBudget()" (change)="checkRest()" />
              <button  (click)="putAllMoney(3)"  class=" btn btn-primary" style="border-top-left-radius: 0;border-bottom-left-radius: 0;"><i class="bi bi-currency-euro"></i></button>
            </div>
            <div  *ngIf="!istGesamtSummeGroesserAlsBudget()||(!InputStaat.valid && (InputStaat.touched || InputStaat.dirty))" class="alert alert-danger">
              <div *ngIf="InputStaat?.errors?.['required']">muss ausgefüllt werden.</div>
              <div *ngIf="InputImmo?.errors?.['pattern']">nur positive Zahlen sind zulässig.</div>
              <div *ngIf="!istGesamtSummeGroesserAlsBudget()">Budget überschritten oder nicht vollständig investiert.</div>
            </div>
          </div>
  
        </div>
  
      
      </div>
    </div>
  
    <div class="row mb-5 cutomRow2" style="margin-top: 50px">
      <h4 class="col col cutomCol2">Hold: Tagesgeld (1 Jahr)</h4>
  
      <div class="col col cutomCol2">
        <img src="../../assets/img/Image8.jpg" class="smallimg" />
      </div>
  
      <div class="col col cutomCol2">
        <div>Anlagebetrag/Kaufsumme (bitte eintragen)</div>
        <div>Rendite: <span>{{zinsTG | number : '1.2-2'}}%</span></div>
  
      </div>
  
      <div class="col cutomCol2">
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
          "
        >
          <div style="display: flex;flex-direction: column; width: 100%;">
            <div style="display: flex;justify-content:center; align-items:center">
            <input style="border-top-right-radius: 0;border-bottom-right-radius: 0;" min="0" id="InputTagesgeld" type="number" class="form-control" name="InputTagesgeld" [(ngModel)]="inputTag" #InputTagesgeld="ngModel" pattern="[0-9]+(\.[0-9]+)?" required (keyup)="istGesamtSummeGroesserAlsBudget()" (change)="checkRest()"/>
            <button  (click)="putAllMoney(4)"  class=" btn btn-primary" style="border-top-left-radius: 0;border-bottom-left-radius: 0;"><i class="bi bi-currency-euro"></i></button>
          </div>
            <div  *ngIf="!istGesamtSummeGroesserAlsBudget()||(!InputTagesgeld.valid && (InputTagesgeld.touched || InputTagesgeld.dirty))" class="alert alert-danger">
              <div *ngIf="InputTagesgeld?.errors?.['required']">muss ausgefüllt werden.</div>
              <div *ngIf="InputImmo?.errors?.['pattern']">nur positive Zahlen sind zulässig.</div>
              <div *ngIf="!istGesamtSummeGroesserAlsBudget()">Budget überschritten oder nicht vollständig investiert.</div>
            </div>
          </div>
        </div>
  
   
      </div>
    </div>
  
  </div>


  <div id="confirmation" style="display: none">
    <button class="btn btn-primary">Angabe gespeichert</button>
  </div>
  <div class="timeIsUp" style="display: none">
    <button class="btn btn-danger">die Zeit ist abgelaufen. dein Budget wird automatisch in dein Tagesgelkonto investiert</button>
  </div>


  <div class="timeIsUp" style="display: none">
    <button class="btn btn-danger">die Zeit ist abgelaufen. dein Budget wird automatisch in dein Tagesgelkonto investiert</button>
  </div>

  <div class="regLog">
    <button class="btn btn-primary" (click)="gotoStep(9)">Zurück</button>
    <div class="designTimer smallerTimer" ><i class="bi bi-hourglass-split"></i>
      {{ timer }}
    </div>  
    <button id="saveBtnResponsive" class="btn btn-primary" (click)="confirmInputInvest()" 
    [disabled]="InputImmo.invalid || InputImmo2.invalid || InputStaat.invalid || InputTagesgeld.invalid || !istGesamtSummeGroesserAlsBudget()|| !isRightRange()|| !isRightRange2()">
      Angaben speichern
    </button>
  </div>
</div>




<!-- ########################################################### umfrage und ausloggen #########################################################################################################################################################################################################   -->

<div *ngIf="step == 12" class="ngIfPlace">
  <h1>Umfrage</h1>



  <div style="margin-bottom: 30px">
    Warum haben Sie sich für Ihre Investition entschieden? (Mehrfachauswahl
    möglich)
  </div>

  <div class="form-check">
    <input class="form-check-input" type="checkbox" value="" id="Rendite" [(ngModel)]="renditeChecked"/>
    <label class="form-check-label" for="Rendite"> Rendite </label>
  </div>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" value="" id="Zinsumfeld" [(ngModel)]="zinsumfeldChecked"/>
    <label class="form-check-label" for="Zinsumfeld"> Zinsumfeld </label>
  </div>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" value="" id="Sicherheit" [(ngModel)]="sicherheitChecked" />
    <label class="form-check-label" for="Sicherheit"> Sicherheit </label>
  </div>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" value="" id="Spekulation" [(ngModel)]="spekulationChecked"/>
    <label class="form-check-label" for="Spekulation"> Spekulation </label>
  </div>
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      value=""
      id="Inflationsschutz"
      [(ngModel)]="inflationsschutzChecked"
    />
    <label class="form-check-label" for="Inflationsschutz">
      Inflationsschutz
    </label>
  </div>

  <div class="timeIsUp" style="display: none">
    <button class="btn btn-danger">die Zeit ist abgelaufen. dein Budget wird automatisch in dein Tagesgelkonto investiert</button>
  </div>
  <!-- <div class="designTimer smallerTimer"  style="position: absolute; bottom: 0; right: 0;"><i class="bi bi-hourglass-split"></i>
    {{ timer }}
  </div> -->

  <button
    class="btn btn-primary"
    (click)="sendCheckedInputs()"
    style="position: absolute; bottom: 0"
  >
    Beenden und ausloggen
  </button>

</div>
