<!-- ########################################################### Dashboard #########################################################################################################################################################################################################   -->

<div class="ngIfPlace respo"  >
    <div class="daschboardBudget">
      <div class="titleDash">
        <h1 id="Dashboard">Dashboard</h1>
        <div class="flexCenterCenter" style="gap: 10px;font-weight: 600;">
          <button class="btn btn-danger" (click)="logout()"><i class="bi bi-box-arrow-left"></i> Logout</button>
          <p *ngIf="!endRound" style="margin-bottom: 0rem;">Spielrunde {{spielrundeCurrent}}</p>
        </div>
      </div>

      <div>
        <h1 *ngIf="endRound" style="text-align: center;"> Herzlichen Glückwunsch </h1>
        <h6 *ngIf="endRound" style="text-align: center;">Sie haben alle 10 Spielrunden erfolgreich beendet.</h6>
      </div>
  
      <div class="budget">
         <!-- <i class="bi bi-info-circle-fill"></i> -->
         <div><i class="bi bi-info-circle customInfo" data-bs-toggle="modal" data-bs-target="#staticBackdrop2"></i> Budget (EK) {{budget| number : '1.2-2'}} €</div>
        <div *ngIf="spielrundeForWealthTable>1"><i class="bi bi-info-circle customInfo" data-bs-toggle="modal" data-bs-target="#staticBackdrop3"></i> Total Return {{totalReturn}} %</div>
      </div>
    </div>
  
      <div class="dashContent" style="display: flex;justify-content: center; align-items: center; height: 70%; width: 100%;">
        <div class="dashCompo" style="display: flex;justify-content: center;align-items: center; width: 50%;height: 100%; position: relative;overflow-y: scroll">
          <div *ngIf="subStep == 6.1"  id="portfolio" style="width: 100%;  display: flex;justify-content: center;align-items: center; flex-direction: column; gap:30px;">
            <div class="gap20" style="display: flex; justify-content: center; align-items: center; gap: 100px ; font-weight: bold;" >
              <div>Portfolio-Übersicht</div>
              <div> Gesamtwert</div>
            </div>
  
            <div class="gap20" style="display: flex; justify-content: center; align-items: center;gap: 50px">
              <div class="flexCenterCenterCol Box" style="background-color: rgb(55, 121, 243);box-shadow:2px 2px 2px 2px #294a8c">
                <div>Immobilien</div>
                <div style="text-decoration: underline; cursor: pointer;"  (click)="subStep = 6.2">mehr anzeigen</div>
              </div>
              <div class="flexCenterCenter moneyBox">
                {{ gesammtImmo | number : '1.2-2'}} €
              </div>
            </div>
  
            <div class="gap20"  style="display: flex; justify-content: center; align-items: center;gap: 50px">
              <div class="flexCenterCenterCol Box" style="background-color: #0BCAF0;box-shadow:2px 2px 2px 2px #084f5e">
                <div>Staatsanleihen</div>
                <div style="text-decoration: underline;cursor: pointer" (click)="subStep = 6.3">mehr anzeigen</div>
              </div>
              <div class="flexCenterCenter moneyBox">
                {{ gesammtStaat | number : '1.2-2'}} €
              </div>
            </div>
            <div class="flexCenterCenterCol" style="width: 80%;height: 50%;">
              <div style="width: 100%; text-align: center; font-weight: bold;">Kennzahlen</div>
   
              <div class="flexCenterCenter" style="box-shadow:2px 2px 2px 2px #294a8c;width: 300px; background-color: rgb(55, 121, 243); color: white; border-radius: 8px;">
   
               <div style="width: 50%;height:50%; gap:10px;" class="flexCenterCenterCol">
                 <!-- <div style="width: 100%;" class="flexCenterCenter">
                     <img src="../../assets/img/Image1.png" class="kennImg"/>
                     BIP-Wachstum
                 </div> -->
                 <div style="width: 100%;" class="flexCenterCenter">
                   <!-- <img src="../../assets/img/Image2.png"  class="kennImg"/> -->
                   <i class="bi bi-bar-chart-line-fill kennImg"></i>
                   Inflation
                 </div>
                 <div style="width: 100%;" class="flexCenterCenter">
  
                  <i class="bi bi-bank kennImg" ></i>
                   Zinssatz (FK)
                 </div>
               </div>
     
               <div style="width: 50%;height:50%; gap:10px;" class="flexCenterCenterCol">
                 <!-- <div>2,00%</div> -->
                 <div>{{inflationText| number : '1.2-2'}}%</div>
                 <div>{{FK_ZinsText | number : '1.2-2'}}%</div>
               </div>
     
             </div>
   
   
           </div>
  
  
            <div style="display: flex; justify-content: center; align-items: center;">
      
            </div>
            <div style="display: flex; justify-content: center; align-items: center;">
      
            </div>
          </div>
      
  
          <div *ngIf="subStep == 6.2" class="flexCenterCenter BoxAn" >
            <div class="flexCenterCenterCol" style="height: 100%;"> 
              <h3>Immobilienportfolio</h3>
        
              <div style="max-height: 100%;overflow-y: scroll;">
                <div style="text-align:center;" *ngFor="let immo of immobought; let i = index">
                  <table>
                    <tr>
                      <td>Kaufperiode: </td>
                      <td>{{immo.periode }}</td>
                    </tr>
                    <tr>
                      <td>Kaufwert:</td>
                      <td>{{immo.buy_value | number : '1.2-2'}}€</td>
                    </tr>
                    <tr>
                      <td>Investition:</td>
                      <td>{{immo.investment | number : '1.2-2'}}€</td>
                    </tr>
                    <tr>
                      <td>Aktueller Wert:</td>
                      <td>{{ immo.current_value| number : '1.2-2'}}€</td>
                    </tr>
                    <tr>
                      <td colspan="2" style="text-align: center; padding: 10px 0;"> 
                        <button class="btn btn-primary" (click)="getImmoCourse(i)">
                          Immobilien Wertentwicklung
                        </button>
                      </td>
                    </tr>
                  </table>

                </div>
              </div>
                        
            </div>
            <button
              class="btn btn-info"
              (click)="subStep = 6.1"
              style="position: absolute; bottom: 10px; right: 10px;color: white;"
            >
              Zurück
            </button>
          </div>
  
          <div *ngIf="subStep == 6.3" class="flexCenterCenter BoxAn" >
            <div class="flexCenterCenterCol" style="height: 100%;"> 
              <h3>Staatsanleihenportfolio</h3>
        
              <div style="max-height: 100%;overflow-y: scroll;">
                <div *ngFor="let staat of staatsanleihen; let i = index" style="text-align:center;">
  
                  <table>
                    <tr>
                      <td>Kaufperiode: </td>
                      <td>{{staat.periode}}</td>
                    </tr>
                    <tr>
                      <td>Staatsanleihe: </td>
                      <td>{{staat.staatsanleihe | number : '1.2-2'}}€</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <button
              class="btn btn-info"
              (click)="subStep = 6.1"
              style="position: absolute; bottom: 10px; right: 10px; color: white;"
            >
              Zurück
            </button>
          </div>

          <div [hidden]="subStep != 6.4" class="flexCenterCenter BoxAn" >
            <div class="chart-container">
              <canvas id="immoChart"></canvas>
            </div>
            <button
            class="btn btn-info"
            (click)="subStep = 6.2"
            style="position: absolute; bottom: 10px; right: 10px; color: white;"
          >
            Zurück
          </button>
          </div>

        </div>
  
        <div *ngIf="spielrundeForWealthTable>1 && chartCounter==1" style="height: 100%;width: 50%;" class="flexCenterCenterCol dashCompoChart">
          <button class="btn btn-outline-primary" (click)="updateChart(2)">show Total return</button>
          <div class="Chart flexCenterCenter" style="width: 100%;height: 100%;">
            <canvas id="chart">{{ chart }}</canvas>
          </div>
        </div>
  
        <div *ngIf="spielrundeForWealthTable>1 && chartCounter==2" style="height: 100%;width: 50%;" class="flexCenterCenterCol dashCompoChart">
          <button class="btn btn-outline-info"  (click)="updateChart(1)">show Depot</button>
          <div class="Chart flexCenterCenter" style="width: 100%;height: 100%;">
            <canvas id="chartTotalReturn">{{ chartTotalReturn }}</canvas>
          </div>
        </div>
      </div>
  
  
  
    <div style="height: 15%; position: relative" class="handelBtn" >
          <button
          *ngIf="!roundPlayed && !roundZero && !endRound" 
            class="btn btn-primary"
            style="position: absolute; bottom: 0; right: 0"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            zu den Handlungsoptionen
          </button>
  
          <button
          *ngIf="roundPlayed && !roundZero && !endRound"
          class="btn btn-primary"
          style="position: absolute; bottom: 0; right: 0"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop1"
          >
          zu den Handlungsoptionen
          </button>
    </div>
    
  
  <!-- Modal -->
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Spielstarten</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <ul>
            <li>Die Spielrunde beginnt, wenn Sie auf "Spiel starten" klicken.</li>
            <li>Diese Aktion kann nur einmal pro Woche getätigt werden.</li>
            <li>Nachdem Sie zugestimmt haben, startet ein Timer von 30 Minuten.</li>
            <li>Sie haben 30 Minuten Zeit, um Ihre Entscheidungen zu treffen.</li>
            <li>Wenn Sie innerhalb von 30 Minuten nicht fertig sind, landet Ihr gesamtes Budget in Ihrem Tagesgeldkonto für diese Runde.</li>
          </ul>
          <p>Viel Spaß und viel Erfolg!</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Spiel noch nicht starten</button>
          <button type="button" class="btn btn-primary"  data-bs-dismiss="modal" (click)="startGame()">Spiel starten</button>
          <!-- <button type="button" class="btn btn-primary"  data-bs-dismiss="modal" (click)="step = 7; startTimer(); roundIsPlayed()" >Spiel starten</button> -->
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal -->
  <div class="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel1">Du hast die Runde der Woche bereits gespielt</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
  
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
  
  <div class="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel2" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel2"></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
          <div class="modal-body">
            Das Budget setzt sich zusammen aus neuer Kapitalausstattung und aktuellen Nettoeinnahmen aus den bisherigen Investitionen (also abzüglich Kreditzins-Zahlungen und abzüglich Bewirtschaftungskosten der Gebäude)
          </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
  
  
  <div class="modal fade" id="staticBackdrop3" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel3" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel3"></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
          <div class="modal-body">
            Der Total Return entspricht: (aktuelle Nettoeinnahmen aus bisherigen Investitionen + und Wertzuwachs der Immobilien)/Gesamtvermögen. (Aufgenommene Kredite für die Hausfinanzierung erhöhen nicht den Vermögensbestand!)
          </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
  
  </div>
  
  
  
  