<!-- ########################################################### EXcel tool #########################################################################################################################################################################################################   -->
<div  class="exccelMenu">


  <div class="form-check">
    <input checked class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="1" [(ngModel)]="selectedHaus">
    <label class="form-check-label" for="flexRadioDefault1">
     Haus 1
    </label>
  </div>
  <div class="form-check">
    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="2" [(ngModel)]="selectedHaus">
    <label class="form-check-label" for="flexRadioDefault2">
     Haus 2
    </label>
  </div>
  
    <button class="btn btn-primary" (click)="calcExcel()" [disabled]="numberOfRounds < spielrundeCurrent">berechnen</button>
  
    <!-- <button class="btn btn-secondary">aktuells Jahr: {{spielrundeCurrent}}</button> -->
  
  </div>
  
  <div  class="ngIfPlace placeRes"  style="width: 100%; display: flex; align-items: center; justify-content: center; ">
  
    
       <div class="leftExcel" style="height: 100%; width: 50%; display: flex; align-items: center; justify-content: center;">
           <div style="height: 70%;width: 95%; display: flex;align-items: center;justify-content: space-around; flex-wrap: wrap; gap:20px">
  
            <!-- <div class="flexCenterCenterCol inputExcel">
              <div>Jahre</div>
                <div>{{numberOfRoundsText}}</div>
                <input type="number" class="form-control cutomIn" [(ngModel)]="numberOfRounds" [min]="spielrundeCurrent">
                <div *ngIf="numberOfRounds < spielrundeCurrent" class="alert alert-danger">Der Wert darf nicht unter dem aktuellen Jahr liegen.</div>
            </div> -->
  
  
  
            <div class="flexCenterCenterCol inputExcel">
              <div>Inflation</div>
                <div>{{inflationText | number : '1.2-2'}}%</div>
                <input type="number" class="form-control cutomIn" [(ngModel)]="inflation">
            </div>
            <div class="flexCenterCenterCol inputExcel">
              <div>FK_Zins</div>
                <div>{{FK_ZinsText  | number : '1.2-2'}}%</div>
                <input type="number" class="form-control cutomIn" [(ngModel)]="FK_Zins">
            </div>
            <div class="flexCenterCenterCol inputExcel">
              <div>EK_Zins</div>
                <div>{{EK_ZinsText  | number : '1.2-2'}}%</div>
                <input type="number" class="form-control cutomIn" [(ngModel)]="EK_Zins" >
            </div>
            <div class="flexCenterCenterCol inputExcel">
              <div>BWK</div>
                <div>{{BWKText  | number : '1.2-2'}}%</div>
                <input type="number" class="form-control cutomIn" [(ngModel)]="BWK">
            </div>
  
            <div class="flexCenterCenterCol inputExcel" *ngIf="selectedHaus==1">
              <div>Hauspreis 1</div>
                <div>{{Hauspreis1Text  | number : '1.2-2'}}€</div>
                <input type="number" class="form-control cutomIn" [(ngModel)]="Hauspreis1" >
            </div>
            <div class="flexCenterCenterCol inputExcel" *ngIf="selectedHaus==1">
              <div>Jahresrohertrag1</div>
                <div>{{jahresrohertrag1Text | number : '1.2-2'}}€</div>
                <input type="number" class="form-control cutomIn" [(ngModel)]="jahresrohertrag1">
            </div>
            <div class="flexCenterCenterCol inputExcel" *ngIf="selectedHaus==2">
              <div>Hauspreis 1</div>
                <div>{{Hauspreis2Text | number : '1.2-2'}}€</div>
                <input type="number" class="form-control cutomIn" [(ngModel)]="Hauspreis2" >
            </div>
            <div class="flexCenterCenterCol inputExcel" *ngIf="selectedHaus==2">
              <div>Jahresrohertrag2</div>
                <div>{{jahresrohertrag2Text | number : '1.2-2'}}€</div>
                <input type="number" class="form-control cutomIn" [(ngModel)]="jahresrohertrag2">
            </div>
  
  
            <div class="flexCenterCenterCol inputExcel">
              <label class="noBold"> Investitionsvolumen €</label>
                <input type="number" class="form-control cutomIn" [(ngModel)]="investitionsvolumen">
            </div>
  
            <div class="flexCenterCenterCol inputExcel">
              <label class="noBold"> <i class="bi bi-info-circle customInfo" data-bs-toggle="modal" data-bs-target="#staticBackdrop4"></i> Kalkulationszins %</label>
                <input type="number" class="form-control cutomIn" [(ngModel)]="kalkulationszins">
            </div>
  
            
           </div>
       </div>
  
       <div style="height: 100%; width: 50% ;display: flex; align-items: center; justify-content: center;">
        <div class="rightExcel" style="height: 60%;width: 95%;">
          <div class="aboveOut" style="height: 75%;width: 100%;display: flex;align-items: center;justify-content: space-around; flex-wrap: wrap; gap:20px">
  
            <div class="flexCenterCenterCol zwiExcel">
              <div>verbleibende Jahre</div>
                <div>{{verbRounds}}</div>
            </div>
            <div class="flexCenterCenterCol zwiExcel">
              <div>FK Volumen</div>
                <div>{{FK_Volumen  | number : '1.2-2'}}€</div>
            </div>
            <div class="flexCenterCenterCol zwiExcel">
              <div>LTV</div>
                <div>{{LTV  | number : '1.2-2'}}%</div>
            </div>
            <div class="flexCenterCenterCol zwiExcel">
              <div>Reinertrag</div>
                <div>{{reinertrag  | number : '1.2-2'}}€</div>
            </div>
            <div class="flexCenterCenterCol zwiExcel">
              <div>Nettoanfangsrendite</div>
                <div>{{nettoanfangsrendite  | number : '1.2-2'}}%</div>
            </div>
            <div class="flexCenterCenterCol zwiExcel">
              <div>FK Zinskosten</div>
                <div>{{FK_Zinskosten  | number : '1.2-2'}}€</div>
            </div>
            <div class="flexCenterCenterCol zwiExcel">
              <div>WACC</div>
                <div>{{kalkulationszinsKapitalwert  | number : '1.2-2'}}%</div>
            </div>
  
          </div>
  
          <div class="underOut" style="height: 25%;width: 100%; display: flex;align-items: center;justify-content: space-around; flex-wrap: wrap; gap:20px; border-top: 1px solid cornflowerblue;">
  
  
            <div class="flexCenterCenterCol outExcel">
              <div>Interner Zinsfuss prognostiziert</div>
                <div>{{internerZinsfussPrognostiziert  | number : '1.2-2'}}%</div>
            </div>
            <div class="flexCenterCenterCol outExcel">
              <div>Kapitalwert prognostiziert</div>
                <div>{{kapitalwertPrognostiziert  | number : '1.2-2'}}€</div>
            </div>
  
          </div>
        </div>
       </div>
    
       <div class="timeIsUp" style="display: none">
        <button class="btn btn-danger">die Zeit ist abgelaufen. dein Budget wird automatisch in dein Tagesgelkonto investiert</button>
      </div>
  
    <div class="regLog forExcel" style="margin-bottom: 38px;">
      <button class="btn btn-primary" (click)="gotoGameStep9()">Zurück</button>
      <div class="designTimer"><i class="bi bi-hourglass-split"></i>
        {{ timer }}
      </div>
      <button class="btn btn-primary" (click)="resizeWord(); gotoGameStep11();">Weiter</button>
    </div>
  
    <div class="modal fade" id="staticBackdrop4" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel4" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel4"></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
            <div class="modal-body">
              Der für Kapitalwertrechnung relevante Kalkulationszins wird, ist ohne Default-Wert, da der korrekte Wert vom sonstigen Investorenverhalten abhängt. Er sollte irgendwo zwischen dem Minimum und dem Maximum aller relevanten Verzinsungen liegen (Zu den relevanten Verzinsungen gehören sowohl die zu erwartenden jährlichen prozentualen Renditen aus den Anlageoptionen, als auch die zu zahlenden (prozentualen) Kapitalkostensätze).
            </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  