import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { UserService } from '../services/userService/user.service';
import { GameService } from '../services/gameService/game.service';
import { of } from 'rxjs/internal/observable/of';
import { from } from 'rxjs/internal/observable/from';
import { concatMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService extends KeycloakAuthGuard {

  spielrundeCurrent=0;
  userProfile:any

  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private userService: UserService,
    private gameService: GameService,
    
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const step: any = localStorage.getItem('step');
    console.log(step);
  
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url
      });
    } else {
      this.gameService.getRoundCurrent().pipe(
        concatMap((res: any) => {
          if (!res.success) {
            return of(null); // Return an observable with null value
          } else {
            this.spielrundeCurrent = res.maxPeriodeValue + 1;
            return from(this.keycloak.loadUserProfile());
          }
        }),
        concatMap((userInfo) => {
          if (userInfo) {
            this.userProfile = userInfo;
            return this.userService.getStud(this.userProfile.email);
          } else {
            return of(null);
          }
        }),
        concatMap((res: any) => {
          if (res && res.success) {
            const user = res.data.user;
            return this.gameService.getPlayedStatus(user, this.spielrundeCurrent);
          } else {
            return of(null);
          }
        })
      ).subscribe((result: any) => {
        if (result && result.success) {
          const roundPlayed = result.daten.roundPlayed;
          console.log('roundPlayed: ' + roundPlayed);
          if (roundPlayed && (state.url.includes('game') || state.url.includes('BerechnungsTool'))) {
            this.router.navigateByUrl('/');
          } else if (step == 12) {
            this.router.navigateByUrl('/');
          } else {
            console.log('do nothing');
          }
        }
      });
    }
  
    // Get the roles required from the route.
    const requiredRoles = route.data['roles'];
  
    // Allow the user to proceed if no additional roles are required to access the route.
    if (!Array.isArray(requiredRoles) || requiredRoles.length === 0) {
      return true;
    }
  
    // Allow the user to proceed if all the required roles are present.
    return requiredRoles.every((role) => this.roles.includes(role));
  }
  
}
